<template>
    <div class="content">
      <div v-for="(page,index) of pages('a')" :key="index">
        <div v-for="item of page" :key="item">
          <span class="txt" @click="firstClick(item)">{{item}}</span>
        </div>
      </div>
      <div v-for="(page,index) of pages('b')" :key="index+'b'">
        <div v-for="item of page" :key="item">
          <span class="txt" @click='otherClick(item)'>{{item}}</span>
        </div>
        <div v-if="index===4" style="align-items: center;cursor: pointer;" >
          <img src="../assets/image/del.png" style="height: 25px;width: 25px;" @click='Del'/>
        </div>
      </div>
    </div>
</template>


<script>
export default{
    data:function(){
       return{
        PlateList:['京','津','冀','晋','蒙','吉','辽','黑','沪','苏','浙','皖','闽','赣','鲁','豫','鄂','湘','粤','桂','琼','渝','川','贵','云','藏','陕','甘','青','宁','新','电','民航'],
        NumberList:['1','2','3','4','5','6','7','8','9','0','A','B','C','D','E','F','G','H','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z','港','澳','军','警','消','应急','学','挂','使'],
        plateno:'',
       }
    },
    computed:{
      pages () {
        return function(index){
          const pages = []
          var data=[]
          if(index==='a')
            data=this.PlateList
          if(index==='b')
            data=this.NumberList
          data.forEach((item, index) => {
            const page = Math.floor(index / (index==="a"?10:9))

            if (!pages[page]) {
              pages[page] = []
            }
            pages[page].push(item)
          })
          return pages
        }
      }
    },
    created(){
      if(this.plateno.length===0){
        this.plateno= this.$store.state.CarDataForm.default_short_plateno
      }
    },
    methods:{
      refreshNo(no){
        this.plateno=no
      },
      firstClick(item){
        if(this.plateno.length>0){
          var firstChar= this.plateno.substring(0, 1)
          var otherChar=this.plateno.substring(1)
          if(firstChar==='民'){
            firstChar=this.plateno.substring(0,2)
              otherChar=this.plateno.substring(2)
          }
          var arr= this.PlateList.filter(a=>a===firstChar)
          let no=''
          if(arr.length>0){
            no=item+otherChar
          }
          else{
            no=item+firstChar+otherChar
          }
          if(firstChar==='民')
            this.plateno=no.substring(0,9)
          else
            this.plateno=no.substring(0,8)
        }
        else
          this.plateno=item
        this.$emit('childValue',this.plateno)
      },
      otherClick(item){
        let no=this.plateno+item
        var firstChar= this.plateno.substring(0, 1)
        if(firstChar==='民')
          this.plateno=no.substring(0,9)
        else
          this.plateno=no.substring(0,8)
        this.$emit('childValue',this.plateno)
      },
      Del(){
        if(this.plateno.length>0){
          if(this.plateno==='民航'){
            this.plateno=''
          }
          else
            this.plateno=this.plateno.substring(0,this.plateno.length-1)
        }
        this.$emit('childValue',this.plateno)
      }
    }
}
</script>


<style scoped>
.content{
    background-color: #C1C2C3;
}
.content div{
  display: flex;
  flex-direction: row;
  margin: 5px;
}
.txt{
  font-size: 17px;
  padding-right: 20px;
  cursor: pointer;
  color: blue;
}
</style>